
(function (window) {

    'use strict';

    var _moment = require('../../bower_components/moment/moment.js');

    window.moment = window.moment || _moment;

    window.angular = require('../../bower_components/angular');


}(window));

(function (window, angular) {

    'use strict';

    require('../../bower_components/angular-animate');
    require('../../bower_components/angular-bootstrap');
    require('../../bower_components/angular-messages');
    require('../../bower_components/angular-resource');
    require('../../bower_components/angular-route');

    var storage = require('../../bower_components/ngstorage/ngStorage.js');

    require('../../bower_components/angular-ui-select/dist/select.js'); // no export in this file
    require('../../bower_components/angular-dynamic-locale/dist/tmhDynamicLocale.js');
    require('../../bower_components/angular-translate/angular-translate.js');
    require('../../bower_components/angular-translate-handler-log/angular-translate-handler-log.js');
    require('../../bower_components/angular-translate-loader-static-files/angular-translate-loader-static-files.js');
    require('../../bower_components/angular-file-upload/dist/angular-file-upload.js');
    require('../../bower_components/angular-loading-bar');
    require('../../bower_components/angular-toastr');

    require('rangy');
    require('rangy/lib/rangy-selectionsaverestore');
    window.taTools = (typeof window !== "undefined" ? window['taTools'] : typeof global !== "undefined" ? global['taTools'] : null);
    require('text-angular-sanitize');
    require('text-angular');

    require('../../bower_components/ngInfiniteScroll/build/ng-infinite-scroll.js');

})(window, window.angular);


(module || {}).exports = 'ivd.vendors';
